<script>
export default {
  props: ["orderDetails", "orderedItemArr", "orderStatusArr","invoice"],
  data() {
    return {
      generatingInvoice: false,
      orderItemAddonArr: [],
      PaymentType: ""
    };
  },
  methods: {
    paymentMode(paymentMethodName, PaymentModeName) {
      if (paymentMethodName != "" && PaymentModeName != "") {
        this.PaymentType = PaymentModeName + " + " + paymentMethodName;
      } else if (paymentMethodName == "" && PaymentModeName != "") {
        this.PaymentType = PaymentModeName;
      } else if (paymentMethodName != "" && PaymentModeName == "") {
        this.PaymentType = paymentMethodName;
      }
      return this.PaymentType;
    },

    async print() {
      var divToPrint = document.getElementById("reciept");
      var newWin = window.open("", "Print-Window");
      newWin.document.open();
      newWin.document.write(
        '<html><body onload="window.print()">' +
          divToPrint.innerHTML +
          "</body></html>"
      );

      newWin.document.close();
      setTimeout(function() {
        newWin.close();
      }, 10000);
    },

    generateInvoice() {
      this.generatingInvoice = true;
      this.axios
        .post(this.$loggedRole+"/invoice/generate", {
          orderID: this.orderDetails.orderID
        })
        .then(result => {
          this.generatingInvoice = false;
          if(result.data.status==0){
            this.$swal({
              icon: "error",
              title: "Generate Invoice",
              text: result.data.message
            });
          }
          else{
            this.$swal({
              icon: "success",
              title: "Generate Invoice",
              text: result.data.message
            }).then(()=>{
              this.invoice.is_available=1;
              this.invoice.invoice_id = result.data.invoice_id;
            });
          }

        }).catch((error)=>{
        this.$swal({
          icon: "error",
          title: "Opps!",
          text: error.response.data.message,
        });
      });
    }
  }
};
</script>

<template>
  <b-modal
    id="modal-lg-orderDetails"
    hide-footer
    size="lg"
    title="Order Details"
    title-class="font-18"
  >
    <!-- <div class="row" v-for="(item, index) in orderDetails"
        :key="index"> -->
    <template v-slot:modal-header>
      <h4 class="modal-title">
        <b
          >Order Details
<!--          <span v-if="orderDetails.mealTypeID != -2">-->
<!--            <button class="btn btn-themeYellow" @click="print">-->
<!--              <i class="uil-print"></i>Print Invoice-->
<!--            </button></span>-->
        </b
        >
      </h4>
      <span v-if="orderDetails.mealTypeID != -2">
        <div
          v-if="generatingInvoice"
          class="text-center text-danger float-end m-1"
        >
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <button
            v-if="invoice.is_available==0"
          :disabled="generatingInvoice"
          class="btn btn-themeYellow"
          @click="generateInvoice"
        >
          <i class="uil-invoice"></i>Generate Invoice
        </button>
        <a
            v-if="invoice.is_available==1"
           target="_blank"
           :href="$ApiUrl +'invoice/'+invoice.invoice_id+'/' +orderDetails.id"
        >
                    <button
                        class="btn btn-themeYellow"
                        style="
                        color: #000 !important;
                        background: #ffc60c !important;
                      "
                    > <i class="uil-invoice"></i>
                   Download Invoice
                    </button>
                  </a>
      </span>
    </template>

    <div class="row">
      <div id="reciept" class="col-lg-6">
        <div style="text-align: right;">
          <span class="logo-lg">
            <img
              alt
              height="20"
              src="@/assets/images/logo.svg"
              style="height:50px;"
            />
          </span>
        </div>
        <div style="text-align:center;">
          <span style="font-size: 18px;font-weight: 500;">
            {{ orderDetails.name }}
          </span>
          <p class="mb-0">
            {{ orderDetails.branchName }}, {{ orderDetails.city }},
            {{ orderDetails.state }}, {{ orderDetails.country }}
          </p>
          <p v-if="orderDetails.gstNO">GSTIN : {{ orderDetails.gstNO }}</p>
        </div>
        <div>
          <p class="mb-0">Order id #{{ orderDetails.orderID }}</p>
          <p style="float:right">Date: {{ orderDetails.date }}</p>
          <p>Customer Name : {{ orderDetails.userName }}</p>
        </div>
        <hr class="new2" />
        <ul
          v-for="(item3, index3) in orderedItemArr"
          :key="index3"
          class="mb-0 ps-2"
          style="list-style: none;"
        >
          <li>
            <div style="display:flex;">
              <span style="min-width: 30px;"> {{ item3.quantity }}x </span>
              <span style="min-width: 250px;">{{ item3.dishTitle }} </span>
              <span v-if="orderDetails.mealTypeID != -2">
                ₹{{ item3.dishPrice }}
              </span>
            </div>
            <div
              v-for="(item4, index4) in item3.orderItemAddonArr"
              :key="index4"
              style="display:flex;"
            >
              <!-- <span style="min-width: 30px;"> {{item4.addonQuantity}} </span> -->
              <span style="min-width: 250px;margin-left: 29px;">
                + {{ item4.addonTitle }}
              </span>
              <span v-if="orderDetails.mealTypeID != -2">
                ₹{{ item4.addonPrice }}
              </span>
            </div>
          </li>
        </ul>
        <hr class="new2" />

        <ul style="list-style: none;">
          <li v-if="orderDetails.corpSubsidy > 0">
            <div style="display:flex;">
              <span
                class="amountTypeSpan mb-1"
                style=" min-width: 157px;margin-left: 28%;"
                >Corporate Subsidy
              </span>
              <span>-₹{{ orderDetails.corpSubsidy }} </span>
            </div>
          </li>
          <li v-if="orderDetails.orderItemSum > 0">
            <div style="display:flex;">
              <span
                class="amountTypeSpan mb-1"
                style=" min-width: 157px;margin-left: 28%;"
                >Subtotal
              </span>
              <span>₹{{ orderDetails.orderItemSum }} </span>
            </div>
          </li>
          <li>
            <div
              v-if="
                orderDetails.voucherIdentifier &&
                  orderDetails.voucherDiscount > 0
              "
              style="display:flex;"
            >
              <span
                class="text-muted mb-1"
                style=" min-width: 157px;margin-left: 28%;"
                >Voucher Discount <br />(#{{ orderDetails.voucherIdentifier }})
              </span>
              <span>-₹{{ orderDetails.voucherDiscount }} </span>
            </div>
          </li>
          <li v-if="orderDetails.Discount > 0 && orderDetails.couponCode">
            <div style="display:flex;color: #45c39a !important;">
              <span
                class="text-muted mb-1"
                style=" min-width: 157px;margin-left: 28%;color: #45c39a !important;"
                >Discount <br />({{ orderDetails.couponCode }})
              </span>
              <span>-₹{{ orderDetails.Discount }} </span>
            </div>
          </li>

          <li v-if="orderDetails.tax > 0">
            <div style="display:flex;">
              <span
                class="text-muted mb-1"
                style=" min-width: 157px;margin-left: 28%;"
                >Tax & Other Charges
              </span>
              <span
                >₹{{
                  Number(orderDetails.tax) + Number(orderDetails.restCharges)
                }}
              </span>
            </div>
          </li>

          <!-- <li>
                                <div style="display:flex;" v-if="orderDetails.restCharges>0">
                                    <span class="text-muted mb-1" style=" min-width: 157px;margin-left: 28%;">Other Charges
                                        </span>
                                      <span>₹{{orderDetails.restCharges}} </span>
                                </div>
                              </li> -->
          <li v-if="orderDetails.walletDiscount > 0">
            <div style="display:flex;">
              <span
                class="text-muted mb-1"
                style=" min-width: 157px;margin-left: 28%;color:#a4827d !important;"
                >Wallet Redemption
              </span>
              <span style="color:#a4827d;"
                >-₹{{ orderDetails.walletDiscount }}
              </span>
            </div>
          </li>

          <li v-if="orderDetails.totalSum > 0">
            <div style="display:flex;font-weight:bold">
              <span
                class="text-muted mb-1"
                style=" min-width: 157px;margin-left: 28%;"
                >Total
              </span>
              <span> ₹{{ orderDetails.totalSum }} </span>
            </div>
          </li>
        </ul>

        <hr class="new2" />
        <!-- {{(orderDetails.paymentMode1) ? orderDetails.paymentMode1: ""}} {{(orderDetails.paymentMode2) ? orderDetails.paymentMode2 : ""}} -->
        <p class="mb-1">
          Payment Mode :
          {{
            paymentMode(
              orderDetails.paymentMethodName,
              orderDetails.PaymentModeName
            )
          }}
        </p>
        <p v-if="orderDetails.transactionID" class="mb-1">
          Transaction ID : {{ orderDetails.transactionID }}
        </p>
        <p class="mb-0">This order is placed via CloudKitch app.</p>
        <p><b>Thank You ! </b></p>
      </div>

      <div
        class="col-lg-6"
        style="box-shadow: 0px 0px 9px rgb(178 179 181 / 12%);"
      >
        <div class="card">
          <div class="card-body">
            <h5>Order Stats</h5>
            <ul
              v-for="(item2, index2) in orderStatusArr"
              :key="index2"
              class="activity-feed mb-0 ps-2"
            >
              <li class="feed-item">
                <div class="feed-item-list">
                  <p class="text-muted mb-1">{{ item2.dateTime }}</p>
                  <h5 class="font-size-16">{{ item2.statusName }}</h5>
                  <p>Updated By: {{ item2.name }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <!-- <h6 class="col-md-8">OrderID : <span style="color:#F3766A;">{{ orderDetails.orderID }}</span></h6>
                         <h6 class="col-md-8">#{{ orderDetails.orderID }}</h6>
                         <div class="row">
                            <div class="md-col-6">
                                <span>POS</span>
                            </div>
                            <div class="md-col-6">
                                <span>Total: ₹ {{ orderDetails.totalSum }}</span>
                            </div>
                            <span> Delivery: {{ orderDetails.created }}</span><br>
                            <span> Invoice No: #{{ orderDetails.orderID }}</span><br>

                         </div> -->
        </div>
      </div>
    </div>
  </b-modal>
</template>
